import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/user.png";
import G1 from "../../../assets/department photos/neuro7.png";
import G2 from "../../../assets/department photos/neurosurgery1.png";
import G3 from "../../../assets/department photos/neuro3.png";

function AAHNeurosurgery() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Mohit Shidhore",
            qualification: "DrNB Neurosurgery",
            location: "Ashwini Hospital, Aditya Ashwini Hospital"
        }
    ];

    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 }
    ];

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <NavbarLocation />

            {/* Neurosurgery Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Gallery" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Popup" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>At Ashwini Hospitals, our Neurosurgery Department is renowned for its cutting-edge facilities and expertise in treating complex neurological conditions. Our state-of-the-art facility boasts advanced neuroimaging technologies, including MRI and CT scanners, which aid in precise diagnosis and treatment planning. Within our neurosurgical suites, equipped with the latest navigation systems and intraoperative imaging capabilities, our highly skilled neurosurgeons perform a wide range of procedures, from intricate brain surgeries to minimally invasive spinal interventions. Our multidisciplinary team, comprising neurosurgeons, neurologists, neuroradiologists, and specialized nurses, collaborates seamlessly to provide personalized care tailored to each patient's needs. We are dedicated to not only delivering exceptional surgical outcomes but also supporting patients and their families through every step of their neurosurgical journey, offering comprehensive pre-operative education, post-operative rehabilitation programs, and ongoing follow-up care.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Neurosurgery Highlights */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Neurosurgery Highlights</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Head and spine injury</li>
                                            <li>Skull base surgery</li>
                                            <li>Surgery for cerebrovascular accidents</li>
                                            <li>AVM Surgery</li>
                                            <li>Spinal tumours</li>
                                            <li>Brain Tumour Surgery</li>
                                            <li>Endoscopic surgery</li>
                                            <li>Surgery of Brain Aneurysm</li>
                                            <li>Degenerative spinal diseases</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AAHNeurosurgery;