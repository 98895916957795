import React from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";

function AAHPhysiotherapy() {
    return (
        <>
            <NavbarLocation />

            {/* Physiotherapy Doctors */}
            <div className="doctors-box">
                <div className="container">

                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Welcome to the Physiotherapy Department at Ashwini Group of Hospitals, where we are dedicated to helping patients restore movement, reduce pain, and improve their quality of life through evidence-based physiotherapy interventions. Our team of skilled physiotherapists utilizes advanced techniques and state-of-the-art facilities to provide personalized care for patients of all ages and conditions.</p>
                                    </div>

                                    {/* Scope of Services */}
                                    <div className="card about-style-two py-0">
                                        <div className="container card-body">
                                            <div className="sec-title">
                                                <h1>Scope of Services</h1>
                                            </div>
                                            <div className="card-text">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Neuro Rehabilitation Services</th>
                                                            <th scope="col">Pulmonary Rehabilitation Services</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Pain Management</td>
                                                            <td>Breathing Exercises</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tilt Table Mobilisation</td>
                                                            <td>Abdominal Muscle Exercises</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Chest Physiotherapy</td>
                                                            <td>Range of Motion Exercises</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Postural Drainage</td>
                                                            <td>Postural Drainage</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Sensory Re-education</td>
                                                            <td>Chest Muscles Strengthening</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cognitive Therapy</td>
                                                            <td>Chest Physiotherapy</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mat Exercises</td>
                                                            <td>Relaxation Techniques</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nutrition</td>
                                                            <td>Spirometer</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Hand Rehabilitation</td>
                                                            <td>Resistance Training</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Functional Electrical Stimulation</td>
                                                            <td>Aerobic Training</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Transcutaneous Electrical Stimulation</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Balance & Coordination Training</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ultrasound Therapy</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Robotic Glove</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Treadmill Training</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gait Training</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Balance Training</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Exercise Therapy</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Speech Therapy</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Strength Training</td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AAHPhysiotherapy;