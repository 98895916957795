import React from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import PsychologyBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/user.png";
import Img2 from "../../assets/doctors/user.png";

function Psychology() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Sashimani Mishra",
            qualification: "Counselling Psychologist",
            location: "Ashwini Wellness"
        },
        {
            image: Img2,
            name: "Dr. Ashrumochan Sahoo",
            qualification: "MD(psychiatry)",
            location: "Ashwini Hospital"
        }
    ];

    return (
        <>
            <Navbar />

            {/* Psychology Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={PsychologyBanner} alt="PsychologyBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Psychology & Psychotherapy</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Psychology Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The Psychology Department at Ashwini Group of Hospitals offers compassionate and comprehensive mental health services to support patients in their journey towards emotional well-being and resilience. Our team of licensed psychologists and therapists provides individualized assessment, therapy, and counseling for a wide range of psychological concerns, including anxiety, depression, trauma, and relationship issues. We believe in a holistic approach to mental health, integrating evidence-based practices with empathy and understanding. Through therapy sessions, psychoeducation, and support groups, we empower patients to overcome challenges, build coping skills, and lead fulfilling lives. Our commitment to mental health advocacy and destigmatization ensures that every individual receives the care and support they deserve.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Psychology;