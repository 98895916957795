import React from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import EconomyHealthPackage from "../../assets/health_package_economy.png";
import MasterHealthPackage from "../../assets/health_package_master.png";
import ExecutiveHealthPackage from "../../assets/health_package_executive.png";
import CardiacHealthPackage from "../../assets/health_package_cardiac.png";
import SeniorCitizenHealthPackage from "../../assets/health_package_senior_citizen.png";
import WomensHealthPackage from "../../assets/health_package_womens.png";

function HealthPackages() {
    const healthPackages = [
        {
            id: 1,
            title: "Ashwini Economy Health Check-ups",
            image: EconomyHealthPackage,
            alt: "EconomyHealthPackage",
            modalId: "firstModal",
            price: "₹3500",
            services: ["HB", "DC", "TLC", "ESR", "FBS", "PPBS", "Na+", "K+", "Urea Creatine", "LIVER FUNCTION TEST", "PFT", "ECG", "URINE R/M", "CHEST X-RAY", "PHYSICIAN CONSULTATION"]
        },
        {
            id: 2,
            title: "Ashwini Master Health Check-ups",
            image: MasterHealthPackage,
            alt: "MasterHealthPackage",
            modalId: "secondModal",
            price: "₹5600",
            services: ["HB", "DC", "TLC", "ESR", "FBS", "PPBS", "Na+", "K+", "Urea Creatine", "LIVER FUNCTION TEST", "PFT", "ECG", "URINE & STOOL R/M", "VDRL", "CHEST X-RAY", "ECHO SCREENING", "LIPID PROFILE", "URIC ACID", "HIV", "HBSAG", "ULTRA SONOGRAPHY", "PHYSICIAN CONSULTATION"]
        },
        {
            id: 3,
            title: "Ashwini Executive Health Check-ups",
            image: ExecutiveHealthPackage,
            alt: "ExecutiveHealthPackage",
            modalId: "thirdModal",
            price: "₹8400",
            services: ["HB", "DC", "TLC", "ESR", "FBS", "PPBS", "Na+", "K+", "Urea Creatine", "LIVER FUNCTION TEST", "PFT", "ECG", "URINE R/M", "CHEST X-RAY", "TMT", "ECHO DOPPLER", "LIPID PROFILE", "URIC ACID", "HIV", "HBSAG", "ULTRA SONOGRAPHY", "CARDIOLOGY CONSULTATION", "PHYSICIAN CONSULTATION"]
        },
        {
            id: 4,
            title: "Ashwini Cardiac Health Check-ups",
            image: CardiacHealthPackage,
            alt: "CardiacHealthPackage",
            modalId: "fourthModal",
            price: "₹6800",
            services: ["FBS", "PPBS", "ESR", "PFT", "ECG", "LIPID PROFILE", "CHEST X-RAY", "CARDIAC ECHO DOPPLER", "TMT", "ULTRA SONOGRAPHY", "CARDIOLOGY CONSULTATION"]
        },
        {
            id: 5,
            title: "Ashwini Senior Citizen Health Check-ups",
            image: SeniorCitizenHealthPackage,
            alt: "SeniorCitizenHealthPackage",
            modalId: "fifthModal",
            price: "₹5900",
            services: ["CBC", "FBS", "PPBS", "ESR", "Na+", "K+", "Urea Creatine", "PTT", "URINE R/M", "LIVER FUNCTION TEST", "ECG", "LIPID PROFILE", "ECHO DOPPLER", "ULTRA SONOGRAPHY", "PHYSICIAN CONSULTATION"]
        },
        {
            id: 6,
            title: "Ashwini Women's Health Check-ups",
            image: WomensHealthPackage,
            alt: "WomensHealthPackage",
            modalId: "sixthModal",
            price: "₹3500",
            services: ["CBC", "ECG", "Ur/Cr", "ECHO", "FBS /2hr PPBS", "TMT", "CHEST X-RAY", "LIPIDS", "URINE R/M"]
        }
    ];

    return (
        <>
            <section className="healthpackage-section py-5">
                <div className="container">
                    <div className="sec-title mb-4">
                        <h1>Ashwini Preventive Health Check-Up Packages</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {healthPackages.map((packageItem, index) => (
                            <div className="col healthpackage-block-one" key={index}>
                                <div className="card h-100 inner-box">
                                    <img src={packageItem.image} className="card-img-top" alt={packageItem.alt} key={index} />
                                    <div className="card-body healthpackage-title">
                                        <h5 className="card-title">{packageItem.title}</h5>
                                    </div>
                                    <div className="card-footer show_package">
                                        <button className="small" data-bs-toggle="modal" data-bs-target={`#${packageItem.modalId}`}>
                                            View More Info <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Health Checkup Modal */}
                    {healthPackages.map((packageItem, index) => (
                        <div className="modal fade" id={packageItem.modalId} tabIndex="-1" aria-labelledby={`${packageItem.modalId}Label`} aria-hidden="true" key={index}>
                            <div className="modal-dialog modal-dialog-scrollable modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title healthpackagetitle" id={`${packageItem.modalId}Label`}>{packageItem.title}</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <h1 className="healthpackageprice">Packages Price - <span>{packageItem.price}</span></h1>
                                        <ul className="ul_list">
                                            {packageItem.services.map((service, i) => (
                                                <li key={i}>{service}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="modal-footer footer_lists">
                                        <h5 className="instructionstitle">Important Instructions</h5>
                                        <ul>
                                            <li>Please take prior appointments</li>
                                            <li>For appointments <span>Call : 9238008811, 7608005555, 7894005555</span></li>
                                            <li>Report at Hospital Health check up desk at 8am on an empty stomach.</li>
                                            <li>Any women in the reproductive age group inform regarding her pregnancy statuses as she should not undergo X-Ray/CT unless medically necessary.</li>
                                            <li>Time taken for check up ranges between 4-5 hours. Breakfast will be provided by hospital as per package.</li>
                                            <li>Collect coupon from Health check up desk.</li>
                                            <li>Option and advice will be given by the consultant on the basis of the test report in the package.</li>
                                            <li>The investigations and cross consultations which are not in the package will be charged extra. The availability of the test reports in such cases will depend on the nature of tests.</li>
                                            <li>Remember to bring your earlier medical reports, if any Health check review consultation should be availed within 14 days beyond 14 days consultation fee will have to be paid in full.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>

    )
}

export default HealthPackages;