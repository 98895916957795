import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/sandip_pradhan.png";
import G1 from "../../../assets/department photos/generalsurgery1.png";
import G2 from "../../../assets/department photos/generalsurgery2.png";

function AHGeneralSurgery() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Sandip Pradhan",
            qualification: "M.S(General Surgery), Consultant General & Gastrointestinal Surgery",
            location: "Ashwini Hospital"
        }
    ];

    const gallery = [
        { image: G1 },
        { image: G2 }
    ]

    const opdTimings = [
        {
            doctor: "Dr. Sandip Pradhan",
            schedule: [
                { day: 'MON', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Hospital' },
                { day: 'WED', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Hospital' },
                { day: 'FRI', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Hospital' }
            ]
        }
    ]

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <NavbarLocation />

            {/* General Surgery Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    {opdTimings.map((opd, index) => (
                                        <div key={index}>
                                            <h5 className="opd">{opd.doctor}</h5>
                                            <table className="table table-hover table-light">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">DAYS</th>
                                                        <th scope="col">TIMINGS</th>
                                                        <th scope="col">CENTRE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {opd.schedule.map((schedule, idx) => (
                                                        <tr className="opd-table" key={idx}>
                                                            <th scope="row">{schedule.day}</th>
                                                            <td>{schedule.time}</td>
                                                            <td>{schedule.centre}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Gallery" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Popup" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The Department of General Surgery at Ashwini of group hospitals comprises super specialist doctors, surgeons, highly trained technicians & nurses which enable us to provide the best patient care and services. We are equipped with modern equipment and use the most advanced techniques and technology along with 24X7 services. Our diagnostic modalities include USG, Pathology, X-ray, C.T. Scan, Endoscopy and Laparoscopy.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Surgical Specialities Available */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Surgical Specialities Available</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Trauma surgery</li>
                                            <li>Laparoscopic surgery</li>
                                            <li>Colorectal surgery</li>
                                            <li>Breast surgery</li>
                                            <li>Vascular surgery</li>
                                            <li>Endocrine surgery</li>
                                            <li>Transplant surgery</li>
                                            <li>Surgical oncology</li>
                                            <li>Cardiothoracic surgery</li>
                                            <li>Pediatric surgery</li>
                                            <li>Maxillofacial surgery</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Services Available */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Services Available</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Upper GI Endoscopy</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AHGeneralSurgery;