import React, { useEffect, useRef } from "react";
import './Career.css'
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import CareerBanner from "../../assets/about_banner.jpg";
import BannerBg from "../../assets/banner-callus.png";
import emailjs from '@emailjs/browser';

function Career() {
    const form = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_tj5tyam', 'template_s01htbp', form.current, { publicKey: 'egxcYC6EeJdXDm0et' })
            .then((result) => {
                console.log('SUCCESS!', result.text);
                alert('Email sent successfully');
            }, (error) => {
                console.log('FAILED...', error);
                alert('Failed to send email');
            });

        e.target.reset();
    };

    return (
        <>
            <Navbar />

            {/* Career Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={CareerBanner} alt="CareerBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Career</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Career */}
            <section className="appointment-section py-5">
                <div className="bg-layer">
                    <img src={BannerBg} alt="BannerBackground" />
                </div>

                <div className="container">
                    <div className="sec-title mt-4">
                        <h1>Build your Career With Us</h1>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="form-inner">
                                <div className="sec-title">
                                    <h2 className="job">Looking for a job?</h2>
                                    <p className="text-start">Don't see a role that matches you? Don't worry. Drop your resume below and we will get in touch with you.</p>
                                </div>

                                <form className="default-form" ref={form} onSubmit={sendEmail}>
                                    <div className="mb-3 form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name*"
                                            name="user_name"
                                            required />
                                    </div>

                                    <div className="row">
                                        <div className="mb-3 form-group col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Phone Number*"
                                                name="user_phone"
                                                required />
                                        </div>
                                        <div className="mb-3 form-group col-sm-6">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email address*"
                                                name="user_email"
                                                required />
                                        </div>
                                    </div>

                                    <div className="mb-3 form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Position Appling For*"
                                            name="user_position"
                                            required />
                                    </div>

                                    <div className="text-start mb-3">
                                        <button type="submit" className="theme-btn btn-one">
                                            <span>Apply Now</span>
                                        </button>
                                    </div>
                                </form>

                                <p className="resume">Drop your resume to the following email address: <span>hrd@ashwinihospitalcuttack.com</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Career;