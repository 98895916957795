import React, { useEffect } from "react";
import "./NavbarLocation.css";
import { useLocation, useNavigate } from "react-router-dom";
import AHLogo from "../../assets/AHLogo.png";
import AAHLogo from "../../assets/AAHLogo.png";
import ATCLogo from "../../assets/ATCLogo.png";
import NABH from "../../assets/NABH.png";
import SEMI from "../../assets/SEMI.png";
import NBE from "../../assets/NBE.png";
import ISCCM from "../../assets/ISCCM.png";
import AahNABH from "../../assets/aah-nabh.png";

function NavbarLocation() {
    const navigate = useNavigate();
    const location = useLocation();

    const handleScrollToSection = (sectionId) => {
        const currentPath = window.location.pathname;

        // Regular expression to match department detail paths
        const departmentPathRegexAH = /^\/AshwiniHospitalCuttack\/[a-zA-Z]+$/;
        const departmentPathRegexATC = /^\/AshwiniTraumaCentre\/[a-zA-Z]+$/;
        const departmentPathRegexAAH = /^\/AdityaAshwiniHospital\/[a-zA-Z]+$/;

        if (departmentPathRegexAH.test(currentPath)) {
            // Redirect to the Ashwini Hospital page with the correct section
            const newUrl = `/AshwiniHospitalCuttack#${sectionId}`;
            navigate(newUrl);
        } else if (departmentPathRegexATC.test(currentPath)) {
            const newUrl = `/AshwiniTraumaCentre#${sectionId}`;
            navigate(newUrl);
        } else if (departmentPathRegexAAH.test(currentPath)) {
            const newUrl = `/AdityaAshwiniHospital#${sectionId}`;
            navigate(newUrl);
        } else {
            // If already on the AGH individual centres page, scroll to the section
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    // Add event listeners for load and hashchange events to handle scrolling
    useEffect(() => {
        const handleHashChange = () => {
            const hash = location.hash.substring(1);
            if (hash) {
                setTimeout(() => {
                    const section = document.getElementById(hash);
                    if (section) {
                        section.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 100);
            }
        };

        handleHashChange(); // Run once on component mount
        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [location.hash]);

    const getLogo = () => {
        const path = location.pathname;

        if (path.includes('AshwiniHospitalCuttack')) {
            return AHLogo;
        } else if (path.includes('AshwiniTraumaCentre')) {
            return ATCLogo;
        } else if (path.includes('AdityaAshwiniHospital')) {
            return AAHLogo;
        } else {
            return AHLogo;
        }
    }

    const getNavItem = () => {
        const path = location.pathname;

        if (path.includes('AdityaAshwiniHospital')) {
            return { label: 'Facilities', section: 'facilities' };
        } else {
            return { label: 'Academics', section: 'academics' }
        }
    }
    const navItem = getNavItem();

    const gradeLogos = () => {
        const path = location.pathname;
        if (path.includes('AshwiniHospitalCuttack')) {
            return [NABH, SEMI, NBE, ISCCM];
        } else if (path.includes('AshwiniTraumaCentre')) {
            return [NABH, SEMI, NBE];
        } else if (path.includes('AdityaAshwiniHospital')) {
            return [AahNABH];
        } else {
            return [NABH, SEMI, NBE, ISCCM]; // Default
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light header-top fixed-top">
                <div className="container">
                    <a className="navbar-brand" href={location.pathname}>
                        <img src={getLogo()} alt="HospitalLogo" />
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar_ul">
                            <li className="nav-item">
                                <a href="#home" className="nav-link" onClick={() => handleScrollToSection('home')}>Home</a>
                            </li>

                            <li className="nav-item">
                                <a href="#aboutus" className="nav-link" onClick={() => handleScrollToSection('aboutus')}>About Us</a>
                            </li>

                            <li className="nav-item">
                                <a href="#departments" className="nav-link" onClick={() => handleScrollToSection('departments')}>Departments</a>
                            </li>

                            <li className="nav-item">
                                <a href={`#${navItem.section}`} className="nav-link" onClick={() => handleScrollToSection(navItem.section)}>{navItem.label}</a>
                            </li>

                            <li className="nav-item">
                                <a href="#contact" className="nav-link" onClick={() => handleScrollToSection('contact')}>Contact</a>
                            </li>
                        </ul>

                        <div className="navbar-brand">
                            {gradeLogos().map((logo, index) => (
                                <img className="me-1 gradeLogo" key={index} src={logo} alt={`Logo${index}`} />
                            ))}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavbarLocation;
