import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/subrat_jena.png";
import Img2 from "../../../assets/doctors/ruchi_gupta.png";
import Img3 from "../../../assets/doctors/user.png";
import G1 from "../../../assets/department photos/plasticsurgery1.png";
import G2 from "../../../assets/department photos/plasticsurgery2.png";
import G3 from "../../../assets/department photos/plasticsurgery3.png";
import G4 from "../../../assets/department photos/plasticsurgery4.png";
import G5 from "../../../assets/department photos/plasticsurgery5.png";
import G6 from "../../../assets/department photos/plasticsurgery6.png";
import G7 from "../../../assets/department photos/plasticsurgery7.png";
import G8 from "../../../assets/department photos/plasticsurgery8.png";
import G9 from "../../../assets/department photos/plasticsurgery9.png";
import G10 from "../../../assets/department photos/plasticsurgery10.png";

function ATCPlasticSurgery() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Subrat Kumar Jena",
            qualification: "M.S(Ortho), M.Ch(Plastic Surgery)",
            location: "Ashwini Trauma Centre"
        },
        {
            image: Img2,
            name: "Dr. Ruchi Gupta",
            qualification: "M.S(General Surgeon), M.Ch(Plastic Surgery)",
            location: "Ashwini Trauma Centre"
        },
        {
            image: Img3,
            name: "Dr. Sudhakar Jena",
            qualification: "M.Ch(Plastic Surgery)",
            location: "Ashwini Trauma Centre"
        }
    ];

    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 },
        { image: G4 },
        { image: G5 },
        { image: G6 },
        { image: G7 },
        { image: G8 },
        { image: G9 },
        { image: G10 }
    ];

    const opdTimings = [
        {
            doctor: "Dr. Subrat Kumar Jena",
            schedule: [
                { day: 'MON', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'WED', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'FRI', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        },
        {
            doctor: 'Dr. Ruchi Gupta',
            schedule: [
                { day: 'MON', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'WED', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'FRI', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        },
        {
            doctor: 'Dr. Sudhakar Jena',
            schedule: [
                { day: 'MON', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'WED', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'FRI', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        }
    ]

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <NavbarLocation />

            {/* Plastic Surgery Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    {opdTimings.map((opd, index) => (
                                        <div key={index}>
                                            <h5 className="opd">{opd.doctor}</h5>
                                            <table className="table table-hover table-light">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">DAYS</th>
                                                        <th scope="col">TIMINGS</th>
                                                        <th scope="col">CENTRE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {opd.schedule.map((schedule, idx) => (
                                                        <tr className="opd-table" key={idx}>
                                                            <th scope="row">{schedule.day}</th>
                                                            <td>{schedule.time}</td>
                                                            <td>{schedule.centre}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Gallery" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Popup" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Plastic surgery is a surgical specialty involving the restoration, reconstruction, or alteration of the human body. It can be divided into main categories like Reconstructive surgery, Cosmetic (Aesthetic) surgery, Hand & Micro-Surgery and Burn management.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Reconstructive Surgery */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Reconstructive Surgery</h1>
                                    </div>
                                    <div className="card-text">
                                        <p>The branch of Plastic Surgery includes craniofacial surgery, hand surgery, microsurgery, and the treatment of burns. While reconstructive surgery aims to reconstruct a part of the body or improve its functioning, cosmetic (or aesthetic) surgery aims at improving the appearance of it. Both of these techniques are used throughout the world. Plastic surgeons use cosmetic surgical principles in all reconstructive surgical procedures as well as isolated operations to improve overall appearance. The Plastic Surgery Department is a robust service segment at Ashwini Hospital providing all the routine as well as advanced and complex procedures in all the branches of Plastic Surgery.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Burns Management */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Burns Management</h1>
                                    </div>
                                    <div className="card-text">
                                        <p>Burn surgery generally takes place in two phases. Acute burn surgery is the treatment immediately after a burn. Reconstructive burn surgery takes place after the burn wounds have healed. Ashwini hospital has facilities for Burns ICU, Isolation and specialised Burns OT.</p>

                                        <p>In Burns surgery, the transfer of skin tissue (skin grafting) is a very common procedure. Skin grafts can be derived from the recipient or donors.</p>

                                        <p>Autografts are taken from the recipient. If absent or deficient of natural tissue, alternatives can be cultured sheets of epithelial cells in vitro or synthetic compounds, such as integra, which consists of silicone and bovine tendon collagen with glycosaminoglycans.</p>
                                        <ul className="ul_list">
                                            <li>Allografts are taken from a donor of the same species.</li>
                                            <li>Xenografts are taken from a donor of a different species.</li>
                                        </ul>
                                        <p>Usually, good results would be expected from plastic surgery that emphasize careful planning of incisions so that they fall within the line of natural skin folds or lines, appropriate choice of wound closure, use of best available suture materials, and early removal of exposed sutures so that the wound is held closed by buried sutures.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ATCPlasticSurgery;