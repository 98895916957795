import React, { useEffect, useState } from "react";
import "../AGH.css";
import { Link } from "react-router-dom";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Slider from "react-slick";
import AHBanner from "../../../assets/AHBanner.png";
import AHImage from "../../../assets/AH.png";
import Professionalstaff from "../../../assets/professional-staff.png";
import EmergencyCase from "../../../assets/emergency-case.png";
import OnlineAppointment from "../../../assets/online-appointment.png";
import FullService from "../../../assets/247-service.png";

import Neurology from "../../../assets/service bg images/neurology.png";
import NeuroSurgery from "../../../assets/service bg images/neurosurgery.png";
import Orthopedics from "../../../assets/service bg images/orthopedics.png";
import EmergencyMedicine from "../../../assets/service bg images/emergency_medicine.png";
import CriticalCare from "../../../assets/service bg images/critical_care.png";
import Pulmonology from "../../../assets/service bg images/pulmonary.png";
import Cardiology from "../../../assets/service bg images/cardiology.png";
import Cardiothoracic from "../../../assets/service bg images/cardiothoracic.png";
import Nephrology from "../../../assets/service bg images/nephrology.png";
import Urology from "../../../assets/service bg images/urology.png";
import GeneralSurgery from "../../../assets/service bg images/general_surgery.png";
import Pediatrics from "../../../assets/service bg images/pediatric.png";
import Anesthesiology from "../../../assets/service bg images/anesthesiology.png";
import Dietetics from "../../../assets/service bg images/dietetics.png";
import Radiology from "../../../assets/service bg images/radiology.png";
import Physiotherapy from "../../../assets/service bg images/physiotherapy.png";
import LaboratoryServices from "../../../assets/service bg images/pathology.png";

import NeuroIcon from "../../../assets/services icons/neurology.png";
import NeurosurgeryIcon from "../../../assets/services icons/neurosurgery.png";
import OrthoIcon from "../../../assets/services icons/orthopedics.png";
import EMIcon from "../../../assets/services icons/emergency medicine.png";
import CCIcon from "../../../assets/services icons/critical care.png";
import PulmoIcon from "../../../assets/services icons/pulmonology.png";
import CardiologyIcon from "../../../assets/services icons/cardiology.png";
import CardiothoracicIcon from "../../../assets/services icons/cardiothoracic.png";
import NephrologyIcon from "../../../assets/services icons/nephrology.png";
import UrologyIcon from "../../../assets/services icons/nephrology.png";
import GSIcon from "../../../assets/services icons/general surgery.png";
import PediatricsIcon from "../../../assets/services icons/pediatric.png";
import AnesthesiologyIcon from "../../../assets/services icons/anesthesiology.png";
import DieteticsIcon from "../../../assets/services icons/diet.png";
import RadiologyIcon from "../../../assets/services icons/rediology.png";
import PhysiotherapyIcon from "../../../assets/services icons/physiotherapy.png";
import LaboratoryServicesIcon from "../../../assets/services icons/pathology.png";

import CourseBg from "../../../assets/shapes/missionvision_bg.png";
import CourseBg1 from "../../../assets/shapes/hexagontop3D.png";

function AshwiniHospital() {
    const [activeTab, setActiveTab] = useState('Courses');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const settings = {
        arrows: false,
        dots: false,
        fade: 1000,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const chooseUsData = [
        {
            title: 'Professional Staff',
            description: 'A skilled team providing exceptional, specialized medical care.',
            icon: Professionalstaff
        },
        {
            title: 'Emergency Case',
            description: 'Rapid response and expert care for critical medical emergencies onsite.',
            icon: EmergencyCase
        },
        {
            title: 'Online Appointment',
            description: 'Streamline medical consultations with easy online booking.',
            icon: OnlineAppointment
        },
        {
            title: '24/7 Services',
            description: 'Round-the-clock medical services ensuring care whenever you need it.',
            icon: FullService
        }
    ];

    const services = [
        {
            link: "/AshwiniHospitalCuttack/Neurology",
            image: Neurology,
            icon: NeuroIcon,
            title: "Neurology"
        },
        {
            link: "/AshwiniHospitalCuttack/Neurosurgery",
            image: NeuroSurgery,
            icon: NeurosurgeryIcon,
            title: "Neurosurgery"
        },
        {
            link: "/AshwiniHospitalCuttack/Orthopedic",
            image: Orthopedics,
            icon: OrthoIcon,
            title: "Orthopedic Surgery"
        },
        {
            link: "/AshwiniHospitalCuttack/Emergency",
            image: EmergencyMedicine,
            icon: EMIcon,
            title: "Emergency Medicine"
        },
        {
            link: "/AshwiniHospitalCuttack/CriticalCare",
            image: CriticalCare,
            icon: CCIcon,
            title: "Critical Care"
        },
        {
            link: "/AshwiniHospitalCuttack/Pulmonology",
            image: Pulmonology,
            icon: PulmoIcon,
            title: "Pulmonology"
        },
        {
            link: "/AshwiniHospitalCuttack/Cardiology",
            image: Cardiology,
            icon: CardiologyIcon,
            title: "Cardiology & Intervention"
        },
        {
            link: "/AshwiniHospitalCuttack/Cardiothoracic",
            image: Cardiothoracic,
            icon: CardiothoracicIcon,
            title: "Cardiothoracic Surgery"
        },
        {
            link: "/AshwiniHospitalCuttack/Nephrology",
            image: Nephrology,
            icon: NephrologyIcon,
            title: "Nephrology & Dialysis"
        },
        {
            link: "/AshwiniHospitalCuttack/Urology",
            image: Urology,
            icon: UrologyIcon,
            title: "Urology"
        },
        {
            link: "/AshwiniHospitalCuttack/GeneralSurgery",
            image: GeneralSurgery,
            icon: GSIcon,
            title: "General Surgery"
        },
        {
            link: "/AshwiniHospitalCuttack/Pediatric",
            image: Pediatrics,
            icon: PediatricsIcon,
            title: "Pediatrics"
        },
        {
            link: "/AshwiniHospitalCuttack/Anesthesiology",
            image: Anesthesiology,
            icon: AnesthesiologyIcon,
            title: "Anesthesiology & Pain Management"
        },
        {
            link: "/AshwiniHospitalCuttack/Dietetics",
            image: Dietetics,
            icon: DieteticsIcon,
            title: "Diet & Dietetics"
        },
        {
            link: "/AshwiniHospitalCuttack/Radiology",
            image: Radiology,
            icon: RadiologyIcon,
            title: "Radiology"
        },
        {
            link: "/AshwiniHospitalCuttack/Physiotherapy",
            image: Physiotherapy,
            icon: PhysiotherapyIcon,
            title: "Physiotherapy Rehabilitation"
        },
        {
            link: "/AshwiniHospitalCuttack/LaboratoryServices",
            image: LaboratoryServices,
            icon: LaboratoryServicesIcon,
            title: "Laboratory Services"
        }
    ]

    return (
        <>
            <NavbarLocation />

            {/* Caraousel */}
            <section className="banner-style-two" id="home">
                <Slider {...settings}>
                    <div className="slider-item">
                        <img src={AHBanner} className="w-100 slider_img" alt="Slider 1" />
                        <div className="container">
                            <div className="content-box">
                                <h1>24 Hour Emergency</h1>
                                <p>Open round the clock for convenience, quick and easy access</p>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item">
                        <img src={AHBanner} className="w-100 slider_img" alt="Slider 2" />
                        <div className="container">
                            <div className="content-box">
                                <h1>Complete Lab Services</h1>
                                <p>Cost-efficient, comprehensive and clinical laboratory services</p>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item">
                        <img src={AHBanner} className="w-100 slider_img" alt="Slider 3" />
                        <div className="container">
                            <div className="content-box">
                                <h1>Medical Professionals</h1>
                                <p>Qualified and certified physicians for quality medical care</p>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>

            {/* Why Choose us */}
            <section className="chooseus-section" id="aboutus">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="content-box">
                                <div className="sec-title light">
                                    <span className="sub-title">Why Choose Us</span>
                                    <h2>Choose The Best For Your <br />Health</h2>
                                </div>

                                <div className="row clearfix">
                                    {chooseUsData.map((item, index) => (
                                        <div className="col-lg-6 col-md-6 col-sm-12 chooseus-block" key={index}>
                                            <div className="chooseus-block-one">
                                                <div className="inner-box">
                                                    <div className="icon-box">
                                                        <img src={item.icon} alt="Professionalstaff" />
                                                    </div>
                                                    <h3>{item.title}</h3>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 d-none d-lg-block bg-layer">
                            <img src={AHImage} alt="AshwiniHospital" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Departments */}
            <section className="service-section my-5" id="departments">
                <div className="container">
                    <div className="sec-title mb-4">
                        <h1>Our Departments</h1>
                    </div>
                    <div className="row">
                        {services.map((service, index) => (
                            <>
                                <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                                    <div className="service-block-one fadeInUp">
                                        <div className="inner-box">
                                            <div className="image-box">
                                                <div className="image">
                                                    <a href={service.link}>
                                                        <img src={service.image} alt="DepartmentImages" />
                                                    </a>
                                                </div>
                                                <div className="icon-box">
                                                    <img src={service.icon} alt="DepartmentImages" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <span><a href={service.link}>{service.title}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </section >

            {/* Academics */}
            <section className="course-section py-4" id="academics">
                <div className="container">
                    <div className="image-shape">
                        <div className="shape-1">
                            <img src={CourseBg1} alt="BgDots" />
                        </div>
                    </div>
                    <div className="sec-title mb-4">
                        <h1>Academics</h1>
                    </div>

                    <div className="row clearfix">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="academic-sidebar">
                                <div className="sidebar-widget">
                                    <div className="academic-title">
                                        <h3>Academic Courses Offered</h3>
                                        <p className="text-title text-decoration-underline">Elevate Your Medical Career with Ashwini Group of Hospitals</p>
                                        <p className="mt-4">Ashwini Group of Hospitals is committed to excellence in healthcare and medical education. We offer advanced academic programs designed to nurture the next generation of medical professionals. Join us in our pursuit of healthcare excellence through our distinguished training programs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="card mb-3 academic-content">
                                <div className="row g-0">
                                    <div className="card-body academic-block">
                                        <h3>Admission Procedure</h3>
                                        <p>A common entrance test (NEET ) is conducted by NBE. Admission for broad specialty is done in January Session and for Super specialty is done in July session. Please contact us for more information:</p>

                                        <p><span className="text-title text-decoration-underline">Ashwini Group of Hospitals</span></p>
                                        <p className="lh-1"><span className="text-title">Mail</span> : academics@ashwinihospitals.in</p>
                                        <p className="lh-1"><span className="text-title">Phone</span> : 8093012752</p>
                                        <p className="lh-1"><span className="text-title">Website</span> :
                                            <a href='https://www.ashwinihospitalcuttack.com/courses/' target="_blank" rel="noopener noreferrer">
                                                www.ashwinihospitalcuttack.com/courses</a>
                                        </p>
                                        <p className="lh-1"><span className="text-title">NBE</span> :
                                            <a href='https://nbe.edu.in' target="_blank" rel="noopener noreferrer">
                                                https://nbe.edu.in</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row clearfix">
                        {/* DNB course */}
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="course-block-one fadeInUp h-100">
                                <div className="course-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h3>DNB Courses</h3>
                                        <h5>3 years</h5>
                                    </div>
                                    <div className="table-content">
                                        <p>Accredited by the National Board of Examinations, we provide top-notch Post Graduate training since 2021. Boasting 400 beds across three hospitals, well-equipped departments, and a modern library, we ensure a high case load and up-to-date resources.</p>
                                    </div>
                                    <div className="table-lists">
                                        <ul>
                                            <li>Respiratory Medicine (Post MBBS & Post Diploma)</li>
                                            <li>Emergency Medicine (Post MBBS)</li>
                                        </ul>
                                    </div>
                                    <div className="text-center mt-auto" data-bs-toggle="modal" data-bs-target="#dnbModal">
                                        <Link to="" className="theme-btn btn-one">
                                            <span>Know More</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* DNB modal */}
                        <div className="modal fade" id="dnbModal" tabIndex="-1" aria-labelledby="dnbModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title course_title" id="dnbModalLabel">DNB Course Details</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        {/* DNB Message */}
                                        <div className="container course_modal">
                                            <div className="card" style={{ border: "0" }}>
                                                <div className="container card-body">
                                                    <div className="text-box mb-0">
                                                        <p>Accredited by the National Board of Examinations, we provide top-notch Post
                                                            Graduate
                                                            training since 2021. Boasting 400 beds across three hospitals, well-equipped
                                                            departments, and a
                                                            modern library, we ensure a high case load and up-to-date resources. Our
                                                            hostels offer
                                                            personalized AC rooms for comfortable living. Stipends are in accordance
                                                            with State Government
                                                            Guidelines. Elevate your medical career with ASHWINI - Where Excellence
                                                            Meets Education!</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* DNB structure */}
                                            <div className="course-box mb-5">
                                                <div className="container course_modal">
                                                    <ul className="nav nav-tabs nav-list">
                                                        <li className="nav-item tab_option">
                                                            <button href="#" className={`nav-link ${activeTab === 'Courses' ? 'active' : ''}`} onClick={() => handleTabClick('Courses')}>Courses</button>
                                                        </li>
                                                        <li className="nav-item tab_option">
                                                            <button href="#" className={`nav-link ${activeTab === 'Fee Structure' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('Fee Structure')}>Fee Structure</button>
                                                        </li>
                                                        <li className="nav-item tab_option">
                                                            <button href="#" className={`nav-link ${activeTab === 'Stipend' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('Stipend')}>Stipend</button>
                                                        </li>
                                                        <li className="nav-item tab_option">
                                                            <button href="#" className={`nav-link ${activeTab === 'Required Documents' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('Required Documents')}>Required Documents</button>
                                                        </li>
                                                    </ul>

                                                    <div className="tab-content">
                                                        {activeTab === 'Courses' && (
                                                            <div>
                                                                <table className="table table-bordered">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col">Site</th>
                                                                            <th scope="col">Speciality</th>
                                                                            <th scope="col">Course</th>
                                                                            <th scope="col">No. of seats</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Ashwini Hospital</td>
                                                                            <td>Respiratory Medicine</td>
                                                                            <td>Post MBBS <br /> Post Diploma</td>
                                                                            <td>1 seat <br /> 1 seat</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Ashwini Hospital</td>
                                                                            <td>Emergency Medicine</td>
                                                                            <td>Post MBBS</td>
                                                                            <td>2 seats</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}

                                                        {activeTab === 'Fee Structure' && (
                                                            <div className="fees">
                                                                <ul>
                                                                    <li><span>1st year</span> : Rs 1,25,000/- (Per Year)</li>
                                                                    <li><span>2nd year</span> : Rs 1,25,000/- (Per Year)</li>
                                                                    <li><span>3rd year</span> : Rs 1,25,000/- (Per Year)</li>
                                                                </ul>
                                                            </div>
                                                        )}

                                                        {activeTab === 'Stipend' && (
                                                            <div className="stipend">
                                                                <ul>
                                                                    <li><span>1st year</span> : Rs. 67,000/-</li>
                                                                    <li><span>2nd year</span> : Rs. 70,000/-.</li>
                                                                    <li><span>3rd year</span> : Rs. 73,000/-</li>
                                                                </ul>
                                                            </div>
                                                        )}

                                                        {activeTab === 'Required Documents' && (
                                                            <div className="documents">
                                                                <ul>
                                                                    <li>DNB/DrNB Joining Report</li>
                                                                    <li>DNB/DrNB fee submission 1st year receipt</li>
                                                                    <li>MCC Provisional Allotment Letter</li>
                                                                    <li>NEET Score Card</li>
                                                                    <li>Provisional admission letter</li>
                                                                    <li>NEET Admit Card</li>
                                                                    <li>Candidate medical registration latest certificate</li>
                                                                    <li>Internship Certificate</li>
                                                                    <li>MBBS Provisional Certificate</li>
                                                                    <li>MBBS All Marksheets</li>
                                                                    <li>10th Pass Certificate</li>
                                                                    <li>10th Marksheets</li>
                                                                    <li>Post MBBS Certificate Marksheets</li>
                                                                    <li>Aadhar Card</li>
                                                                    <li>PAN Card</li>
                                                                    <li>Cancelled Cheque</li>
                                                                    <li>Relieved Order(if any)</li>
                                                                    <li>Transfer Certificate or College Leaving Certificate</li>
                                                                    <li>Character Certificate</li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Facilities Available */}
                                            <div className="facility-box">
                                                <div className="sidebar">
                                                    <div className="academic-title">
                                                        <h3>Facilities Available</h3>
                                                        <ul>
                                                            <li>24 Hour Emergency & Day Care</li>
                                                            <li>Outpatient Services</li>
                                                            <li>Intensive Care Unit</li>
                                                            <li>Advanced  Dialysis  Service</li>
                                                            <li>Advanced  Respiratory  Lab</li>
                                                            <li>24x7 Hi-tech Ambulance Services</li>
                                                            <li>24x7 Laboratory Diagnostic Services</li>
                                                            <li>24x7 Imaging Services</li>
                                                            <li>24x7 Pharmacy</li>
                                                            <li>Comprehensive Health Check-up Facility</li>
                                                            <li>Nutrition & Dietetics</li>
                                                            <li>Operation Theatre(Modular)</li>
                                                            <li>Suite</li>
                                                            <li>Cafeteria & Canteen</li>
                                                            <li>Mortuary</li>
                                                            <li>Oxygen Plant 30NM3 PSA</li>
                                                            <li>Generator for 24x7 Power Supply</li>
                                                            <li>Laundry</li>
                                                            <li>Hostel & Guest House</li>
                                                            <li>Library</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* DrNB course */}
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="course-block-one fadeInUp h-100">
                                <div className="course-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h3>DrNB Courses</h3>
                                        <h5>3 years</h5>
                                    </div>
                                    <div className="table-content">
                                        <p>Accredited by the National Board of Examinations in Medical Sciences, New Delhi, we proudly offer Post Graduate training to DrNB Trainees in various fields since 2023.</p>
                                    </div>
                                    <div className="table-lists">
                                        <ul>
                                            <li>Neurology (Post PG)</li>
                                            <li>Neurosurgery (Post PG)</li>
                                        </ul>
                                    </div>
                                    <div className="text-center mt-auto" data-bs-toggle="modal" data-bs-target="#drnbModal">
                                        <Link to='' className="theme-btn btn-one">
                                            <span>Know More</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* DrNB modal */}
                        <div className="modal fade" id="drnbModal" tabIndex="-1" aria-labelledby="drnbModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title course_title" id="drnbModalLabel">DrNB Course Details</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        {/* DrNB Message */}
                                        <div className="container course_modal">
                                            <div className="card" style={{ border: "0" }}>
                                                <div className="container card-body">
                                                    <div className="text-box mb-0">
                                                        <p>Accredited by the National Board of Examinations in Medical Sciences, New Delhi, we proudly offer Post Graduate training to DrNB Trainees in various fields since 2023. Our well-equipped departments span three hospitals with a total bed strength of 400. Explore our state-of-the-art library housing the latest reference books and journals. Benefit from full-time internet facilities and tablets for seamless learning. Hostel facilities are available as per availability. Stipends are in accordance with State Government Guidelines. Join ASHWINI for an enriching and impactful medical education experience.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* DrNB structure */}
                                            <div className="course-box mb-5">
                                                <div className="container course_modal">
                                                    <ul className="nav nav-tabs nav-list">
                                                        <li className="nav-item tab_option">
                                                            <button href="#" className={`nav-link ${activeTab === 'Courses' ? 'active' : ''}`} onClick={() => handleTabClick('Courses')}>Courses</button>
                                                        </li>
                                                        <li className="nav-item tab_option">
                                                            <button href="#" className={`nav-link ${activeTab === 'Fee Structure' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('Fee Structure')}>Fee Structure</button>
                                                        </li>
                                                        <li className="nav-item tab_option">
                                                            <button href="#" className={`nav-link ${activeTab === 'Stipend' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('Stipend')}>Stipend</button>
                                                        </li>
                                                        <li className="nav-item tab_option">
                                                            <button href="#" className={`nav-link ${activeTab === 'Required Documents' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('Required Documents')}>Required Documents</button>
                                                        </li>
                                                    </ul>

                                                    <div className="tab-content">
                                                        {activeTab === 'Courses' && (
                                                            <div>
                                                                <table className="table table-bordered">
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th scope="col">Site</th>
                                                                            <th scope="col">Speciality</th>
                                                                            <th scope="col">Course</th>
                                                                            <th scope="col">No. of seats</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Ashwini Hospital</td>
                                                                            <td>Neurology</td>
                                                                            <td>Post PG</td>
                                                                            <td>2 seats</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Ashwini Hospital</td>
                                                                            <td>Neurosurgery</td>
                                                                            <td>Post PG</td>
                                                                            <td>2 seats</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}

                                                        {activeTab === 'Fee Structure' && (
                                                            <div className="fees">
                                                                <ul>
                                                                    <li><span>1st year</span> : Rs 1,25,000/- (Per Year)</li>
                                                                    <li><span>2nd year</span> : Rs 1,25,000/- (Per Year)</li>
                                                                    <li><span>3rd year</span> : Rs 1,25,000/- (Per Year)</li>
                                                                </ul>
                                                            </div>
                                                        )}

                                                        {activeTab === 'Stipend' && (
                                                            <div className="stipend">
                                                                <ul>
                                                                    <li><span>1st year</span> : Rs. 67,000/-</li>
                                                                    <li><span>2nd year</span> : Rs. 70,000/-.</li>
                                                                    <li><span>3rd year</span> : Rs. 73,000/-</li>
                                                                </ul>
                                                            </div>
                                                        )}

                                                        {activeTab === 'Required Documents' && (
                                                            <div className="documents">
                                                                <ul>
                                                                    <li>DNB/DrNB Joining Report</li>
                                                                    <li>DNB/DrNB fee submission 1st year receipt</li>
                                                                    <li>MCC Provisional Allotment Letter</li>
                                                                    <li>NEET Score Card</li>
                                                                    <li>Provisional admission letter</li>
                                                                    <li>NEET Admit Card</li>
                                                                    <li>Candidate medical registration latest certificate</li>
                                                                    <li>Internship Certificate</li>
                                                                    <li>MBBS Provisional Certificate</li>
                                                                    <li>MBBS All Marksheets</li>
                                                                    <li>10th Pass Certificate</li>
                                                                    <li>10th Marksheets</li>
                                                                    <li>Post MBBS Certificate Marksheets</li>
                                                                    <li>Aadhar Card</li>
                                                                    <li>PAN Card</li>
                                                                    <li>Cancelled Cheque</li>
                                                                    <li>Relieved Order(if any)</li>
                                                                    <li>Transfer Certificate or College Leaving Certificate</li>
                                                                    <li>Character Certificate</li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Facilities Available */}
                                            <div className="facility-box">
                                                <div className="sidebar">
                                                    <div className="academic-title">
                                                        <h3>Facilities Available</h3>
                                                        <ul>
                                                            <li>24 Hour Emergency & Day Care</li>
                                                            <li>Outpatient Services</li>
                                                            <li>Intensive Care Unit</li>
                                                            <li>Advanced  Dialysis  Service</li>
                                                            <li>Advanced  Respiratory  Lab</li>
                                                            <li>24x7 Hi-tech Ambulance Services</li>
                                                            <li>24x7 Laboratory Diagnostic Services</li>
                                                            <li>24x7 Imaging Services</li>
                                                            <li>24x7 Pharmacy</li>
                                                            <li>Comprehensive Health Check-up Facility</li>
                                                            <li>Nutrition & Dietetics</li>
                                                            <li>Operation Theatre(Modular)</li>
                                                            <li>Suite</li>
                                                            <li>Cafeteria & Canteen</li>
                                                            <li>Mortuary</li>
                                                            <li>Oxygen Plant 30NM3 PSA</li>
                                                            <li>Generator for 24x7 Power Supply</li>
                                                            <li>Laundry</li>
                                                            <li>Hostel & Guest House</li>
                                                            <li>Library</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Address */}
            <section className="my-4" id="contact">
                <div className="sec-title">
                    <h1>Contact Us</h1>
                </div>

                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-7 col-md-12 col-sm-12 ">
                            <div className="card location-classic-content">
                                <div className="row g-0 inner-box">
                                    <div className="mapouter">
                                        <div className="gmap_canvas">
                                            <iframe
                                                title="Map of Ashwini Hospital"
                                                width="725"
                                                height="300"
                                                id="gmap_canvas"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3737.7932138532087!2d85.84686017469981!3d20.473684706612918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1912003d8d0edd%3A0x437827556c838e2!2sAshwini%20Hospital!5e0!3m2!1sen!2sin!4v1716445852741!5m2!1sen!2sin"
                                                frameborder="0"
                                                scrolling="no"
                                                marginheight="0"
                                                marginwidth="0">
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="default-sidebar">
                                <div className="sidebar-widget">
                                    <div className="location-title">
                                        <h3>Address</h3>
                                        <p>CDA, Sector-1, Cuttack-753015, Odisha</p>
                                    </div>
                                    <div className="location-title">
                                        <h3>Contact Info</h3>
                                        <ul>
                                            <li><span></span>Phone : 0671-2363007/08/09/10 (Fax)</li>
                                            <li>Reception : 9238008811</li>
                                            <li>Ambulance : 9238008800</li>
                                            <li>Emergency : 9238008822</li>
                                            <li>Email : admin@ashwinihospitalcuttack.com</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* Back button */}
            <div className="text-center mb-3">
                <a href="/" className="theme-btn btn-one">
                    <span>Back to Ashwini Group</span>
                </a>
            </div>
        </>
    )
}

export default AshwiniHospital;