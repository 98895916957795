import React from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import DermatologyBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/monali_pattnaik.jpg";

function Dermatology() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Monali Pattnaik",
            qualification: "Consultant Dermatology",
            location: "Ashwini Wellness, Aditya Ashwini Hospital"
        }
    ];

    return (
        <>
            <Navbar />

            {/* Dermatology Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={DermatologyBanner} alt="DermatologyBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Cosmetic Surgery, Dermatology & Lasers</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Dermatology Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>In Aesthetic surgery we provide comprehensive treatment in the following areas</h1>
                                    </div>

                                    <div className="card-text">
                                        <p><span className="text-title">Abdominoplasty : </span>Reshaping and firming of the abdomen</p>

                                        <p><span className="text-title">Blepharoplasty : </span>Eyelid surgery</p>

                                        <p><span className="text-title">Mammoplasties : </span> Breast augmentations for small and atrophic breasts, Reduction mammoplasty in women with gigantomastia (uncomfortably large breasts) and for men with gynecomastia, Mastopexy ( Lifting or reshaping of breasts to make them less saggy, often after weight loss after a pregnancy, for example)</p>

                                        <p><span className="text-title">Rhinoplasty (“nose job”) :</span>Reshaping of the nose</p>

                                        <p><span className="text-title">Liposuction (“suction lipectomy”) : </span>Removal of fat deposits by traditional suction technique or ultrasonic energy to aid fat removal</p>

                                        <p><span className="text-title">Calf Augmentation :</span>Done by silicone implants or fat transfer to add bulk to calf muscles</p>

                                        <p><span className="text-title">Lip augmentation : </span>Alter the appearance of the lips by increasing their fullness through surgical enlargement with lip implants or nonsurgical enhancement with injectable fillers</p>

                                        <p><span className="text-title">Cheiloplasty : </span>Surgical reconstruction of the lip</p>

                                        <p><span className="text-title">Otoplasty (“ear surgery”/”ear pinning”) : </span>Reshaping of the ear, most often done by pinning the protruding ear closer to the head</p>

                                        <p><span className="text-title">Rhytidectomy (“face lift”) : </span>Removal of wrinkles and signs of aging from the face. Neck lift: tightening of lax tissues in the neck. This procedure is often combined with a facelift for lower face rejuvenation. Browplasty (“brow lift” or “forehead lift”): elevates eyebrows, smooths forehead skin. Midface lift (“cheek lift”): tightening of the cheeks</p>

                                        <p><span className="text-title">Genioplasty : </span>Augmentation of the chin with an individual's bones or with the use of an implant, usually silicone, by suture of the soft tissue</p>

                                        <p><span className="text-title">Cheek augmentation (“cheek implant”) : </span>Implants to the cheek and dimple creation</p>

                                        <p><span className="text-title">Orthognathic Surgery : </span>Altering the upper and lower jaw bones (through osteotomy) to correct jaw alignment issues and correct the teeth alignment</p>

                                        <p><span className="text-title">Fillers injections : </span>Collagen, fat, and other tissue filler injections, such as hyaluronic acid</p>

                                        <p><span className="text-title">Brachioplasty (“Arm lift”) : </span>Reducing excess skin and fat between the underarm and the elbow</p>

                                        <p><span className="text-title">Laser Skin Rejuvenation or laser resurfacing : </span>The lessening of depth of facial pores and exfoliation of dead or damaged skin cells</p>
                                        <p><span className="text-title">Zygoma reduction / augmentation plasty : </span>Reducing /increasing the facial width by performing osteotomy and resecting part of the zygomatic bone and arch</p>

                                        <p><span className="text-title">Jaw reduction : </span>Reduction of the mandible angle to smooth out an angular jaw and creating a slim jaw line</p>

                                        <p><span className="text-title">Hair transplantation : </span>Hair transplantation of scalp or eye brows or moustache / beard are is done using scalp or body hair with either FUT or FUE techniques. These surgeries are safe and without any side effects improving the facial appearance and self esteem of an individual to a great extent</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dermatology;