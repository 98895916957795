import React from "react";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import DieteticsBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/user.png";
import Img2 from "../../assets/doctors/user.png";
import Img3 from "../../assets/doctors/user.png";

function Dietetics() {
    const slides = [
        {
            image: Img1,
            name: "Dt. Bithika Jena",
            qualification: "M.Sc(Nutrition & Dietetics)",
            location: "Ashwini Hospital"
        },
        {
            image: Img2,
            name: "Dt. Swaleha Shirin",
            qualification: "Nutrition & Dietetics",
            location: "Ashwini Trauma Centre"
        },
        {
            image: Img3,
            name: "Dt. Raksha Baheti",
            qualification: "Specialised with Weight Management/Diabetes/High Blood Pressure/PCOS",
            location: "Aditya Ashwini Hospital"
        }
    ];

    return (
        <>
            <Navbar />

            {/* Dietetics Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={DieteticsBanner} alt="DieteticsBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Dietetics</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Dietetics Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>At Ashwini Group of Hospitals, our Nutrition Department is dedicated to promoting optimal health and well-being through personalized nutritional care. Our team of expert dietitians and nutritionists provides comprehensive dietary assessments, counseling, and education to help you achieve your health goals. Whether you need support for managing a chronic condition, weight loss, or simply want to improve your overall nutrition, we are here to guide you every step of the way.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Nutritional Services */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Our Nutrition Services Include</h1>
                                    </div>
                                    <div className="card-text">
                                        <p><span className="text-title">Nutritional Assessments : </span>Comprehensive evaluations of your dietary habits, nutritional needs, and health status to develop personalized nutrition plans.</p>

                                        <p><span className="text-title">Medical Nutrition Therapy : </span>Customized dietary interventions for managing chronic conditions such as diabetes, hypertension, cardiovascular disease, kidney disease, and gastrointestinal disorders.</p>

                                        <p><span className="text-title">Weight Management : </span>Individualized weight loss and weight gain programs designed to promote sustainable, healthy body weight through balanced nutrition and lifestyle changes.</p>

                                        <p><span className="text-title">Maternal Nutrition :</span>Specialized nutritional care for expecting and breastfeeding mothers to support maternal and fetal health during pregnancy and lactation.</p>

                                        <p><span className="text-title">Sports Nutrition : </span>Guidance for athletes and active individuals on optimizing performance, recovery, and overall health through tailored nutritional strategies.</p>

                                        <p><span className="text-title">Dietary Counseling for Seniors : </span>Nutritional support for older adults to address age-related changes in metabolism, dietary needs, and chronic disease management.</p>

                                        <p><span className="text-title">Clinical Nutrition Support : </span>Nutritional care for hospitalized patients, including enteral and parenteral nutrition, to support recovery and healing.</p>

                                        <p><span className="text-title">Nutritional Education Workshops : </span>Group sessions and workshops to educate the community on various nutrition topics, healthy eating habits, meal planning, and cooking demonstrations.</p>

                                        <p><span className="text-title">Corporate Wellness Programs : </span>Nutritional assessments and wellness initiatives designed for workplaces to enhance employee health and productivity.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Why Choose */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Why Choose Ashwini Group of Hospitals for Nutritional Care ?</h1>
                                    </div>
                                    <div className="card-text">
                                        <p><span className="text-title">Experienced Dietitians : </span>Our team of registered dietitians and nutritionists are highly trained and experienced in providing evidence-based nutritional care.</p>

                                        <p><span className="text-title">Personalized Approach : </span>We offer tailored nutrition plans that meet the unique needs and preferences of each individual.</p>

                                        <p><span className="text-title">Holistic Care : </span>We work closely with other healthcare professionals to provide integrated care that addresses all aspects of your health.</p>

                                        <p><span className="text-title">Educational Resources :</span>We provide practical tools and resources to empower you to make informed dietary choices for long-term health benefits.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dietetics;