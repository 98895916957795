import React from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";

function AAHENT() {
    return (
        <>
            <NavbarLocation />

            {/* ENT Doctors */}
            <div className="doctors-box">
                <div className="container">

                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>At Aditya Ashwini Hospitals, our ENT Department is dedicated to providing comprehensive care for conditions affecting the ear, nose, and throat. Our team of experienced otolaryngologists and healthcare professionals is committed to delivering advanced diagnostic and treatment services to patients of all ages. Whether you are dealing with a common ear infection or a complex head and neck condition, we are here to provide the highest standard of care.</p>
                                    </div>
                                </div>

                                {/* ENT Services */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Our ENT Services Includes</h1>
                                        </div>
                                        <div className="card-text">
                                            <p><span className="text-title">General ENT Care : </span>Comprehensive evaluation and treatment of common ENT conditions such as sinusitis, ear infections, and throat problems.</p>

                                            <p><span className="text-title">Hearing and Balance Disorders : </span>Diagnosis and management of hearing loss, tinnitus, vertigo, and balance disorders using the latest audiological testing and treatments.</p>

                                            <p><span className="text-title">Allergy Management : </span>Identification and treatment of nasal and sinus allergies through allergy testing, immunotherapy, and medical management.</p>

                                            <p><span className="text-title">Sinus and Nasal Disorders :</span>Advanced care for chronic sinusitis, nasal obstructions, and other sinus-related issues, including minimally invasive sinus surgery.</p>

                                            <p><span className="text-title">Voice and Swallowing Disorders :</span>Specialized evaluation and treatment for voice disorders, hoarseness, swallowing difficulties, and related conditions.</p>

                                            <p><span className="text-title">Pediatric ENT :</span>Expert care for children with ENT issues, including tonsillitis, ear infections, hearing loss, and congenital abnormalities.</p>

                                            <p><span className="text-title">Head and Neck Surgery :</span>Surgical interventions for tumors, cancers, and other conditions affecting the head and neck, performed by skilled surgeons with a multidisciplinary approach.</p>

                                            <p><span className="text-title">Sleep Apnea and Snoring :</span>Comprehensive management of sleep-related breathing disorders, including obstructive sleep apnea and snoring, with both surgical and non-surgical treatments.</p>

                                            <p><span className="text-title">Cochlear Implants and Hearing Aids :</span>Evaluation, fitting, and management of cochlear implants and hearing aids for patients with significant hearing loss.</p>

                                            <p><span className="text-title">Facial Plastic and Reconstructive Surgery :</span>Cosmetic and reconstructive procedures for facial trauma, deformities, and aesthetic enhancements.</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Why Choose */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Why Choose Aditya Ashwini Hospital for ENT Care ?</h1>
                                        </div>
                                        <div className="card-text">
                                            <p><span className="text-title">Experienced Specialists : </span>Our ENT doctors are highly trained and experienced in managing a wide range of ear, nose, and throat conditions.</p>

                                            <p><span className="text-title">Advanced Technology : </span>We utilize state-of-the-art diagnostic tools and surgical equipment to ensure precise and effective treatments.</p>

                                            <p><span className="text-title">Patient-Centered Approach : </span>We focus on individualized care plans tailored to meet the unique needs of each patient.</p>

                                            <p><span className="text-title">Comprehensive Care :</span>From initial consultation to post-treatment follow-up, we provide seamless and continuous care.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AAHENT;