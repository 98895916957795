import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import G1 from "../../../assets/department photos/criticalcare1.png";

function ATCCriticalCare() {
    const gallery = [
        { image: G1 }
    ]

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <NavbarLocation />

            {/* Critical Care Doctors */}
            <div className="doctors-box">
                <div className="container">

                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Gallery" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Popup" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The Department of Critical Care Medicine is a state of art centre in the silver city of Cuttack. This speciality of Medicine caters to looking after critically ill patients from medical and surgical specialities with life threatening conditions and multiorgan failure requiring comprenhensive and constant monitoring often not possible in the regular wards.</p>

                                        <p>We run a 25 bedded ICU with sophisticated equipment in the 2nd floor of the Block building. We provide round the clock evidence based and affordable care to our patients with our dedicated team of qualified intensivists, critical care nurses, physiotherapists and dieticians. It is also a centre for IDCCM/ CTCCM/ ISCCN programme for the training of doctors and nurses who are interested to make their careers in critical care medicine.</p>

                                        <span className="text-title">Work at ICU</span>
                                        <p>The patients are given care by qualified intensivists, resident doctors and nursing staff .The department has its own evidence based protocol to provide quality care at all times to critically ill patients. The patient care is provided by dedicated team of doctors and nurses in shifts and written handover of information between physician to physician and nursing staff to nursing staff to avoid any miscommunication. The department has monthly scheduled departmental meetings, mortality and morbidity meetings. The patient data are captured at frequent intervals for ensuring patient safety improving patient care.Scheduled routine medical audits are done in various aspects of patient care and protocols are revised and newer policies would be implemented.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Clinical Services */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Clinical Services</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Bedside bronchoscopy</li>
                                            <li>24 hour presence of residents at the bedside and faculty cover</li>
                                            <li>Bedside echocardiography</li>
                                            <li>Bedside Ultrasonography</li>
                                            <li>Continuous renal replacement therapy at the bedside</li>
                                            <li>Bedside percutaneous dilatational tracheostomy</li>
                                            <li>Assessment, advice and transfer of sick patients to the ICU</li>
                                            <li>Airway assessment and management</li>
                                            <li>Placement of central venous lines</li>
                                            <li>Advice regarding parenteral nutrition of patients</li>
                                            <li>PACS- (picture archiving and communication system) installed in all the systems
                                                for storage and access to images from multiple modalities</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ATCCriticalCare;