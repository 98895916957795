import React from "react";
import './Footer.css'
import { Link } from "react-router-dom";
import FooterBg from "../../assets/shapes/footerBg.png";
import FooterDot1 from "../../assets/shapes/footerdot1.png";
import FooterDot2 from "../../assets/shapes/footerdot2.png";
import FooterLogo from "../../assets/footerlogo.png";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { AiFillYoutube, AiFillGoogleCircle, AiFillInstagram } from "react-icons/ai";
import { CiMail } from "react-icons/ci";
import { PiPhoneCallThin } from "react-icons/pi";

function Footer() {
    return (
        <footer className="main-footer" id="footer">
            <div className="pattern-layer">
                <div className="pattern-1">
                    <img src={FooterBg} alt="FooterBackground" />
                </div>
                <div className="pattern-2">
                    <img src={FooterDot1} alt="Dot" />
                </div>
                <div className="pattern-3">
                    <img src={FooterDot2} alt="Dot" />
                </div>
                <div className="pattern-4"></div>
            </div>

            <div className="widget-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="logo-widget">
                                <figure className="footer-logo">
                                    <Link to={"/"}><img src={FooterLogo} alt="FooterLogo" /></Link>
                                </figure>
                                <ul className="social-links">
                                    <li><a href="https://www.facebook.com/ashwinihospitalcuttackodisha" target="_blank" rel="noreferrer"><BiLogoFacebookCircle className="footer_icon" /></a></li>
                                    <li><a href="https://www.youtube.com/@ashwinihospital9330" target="_blank" rel="noreferrer"><AiFillYoutube className="footer_icon" /></a></li>
                                    <li><a href="https://g.co/kgs/ZiAkrcJ" target="_blank" rel="noreferrer"><AiFillGoogleCircle className="footer_icon" /></a></li>
                                    <li><a href="https://www.instagram.com/ashwinihospitalcuttack/" target="_blank" rel="noreferrer"><AiFillInstagram className="footer_icon" /></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="links-widget ml">
                                <div className="footer-title">
                                    <h3>Quick Links</h3>
                                </div>
                                <ul className="links-list">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/About">About Us</a></li>
                                    <li><a href="/Departments">Departments</a></li>
                                    <li><a href="/Academic">Academics</a></li>
                                    <li><a href="/Career">Career</a></li>
                                    <li><a href="/Blog">Blog</a></li>
                                    <li><a href="/Contact">Contact Us</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="links-widget">
                                <div className="footer-title">
                                    <h3>Our Locations</h3>
                                </div>
                                <ul className="links-list">
                                    <li><a href="/AshwiniHospitalCuttack">Ashwini Hospital</a></li>
                                    <li><a href="/AshwiniTraumaCentre">Ashwini Trauma Centre</a></li>
                                    <li><a href="/AdityaAshwiniHospital">Aditya Ashwini Hospital</a></li>
                                    {/* <li><a href='https://ashwiniwellness.com/' target="_blank" rel="noreferrer">Ashwini Wellness</a></li>
                                    <li><a href='https://ashwinicollege.in/' target="_blank" rel="noreferrer">Ashwini College of Nursing</a></li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12">
                            <div className="contact-widget">
                                <div className="footer-title">
                                    <h3>Emergency Contact</h3>
                                </div>
                                <ul className="info-list">
                                    <li>
                                        <PiPhoneCallThin className="contact_icon" />
                                        <a href="tel:01989526503" className="contact_text">+91 923 800 8800</a>
                                    </li>
                                    <li>
                                        <CiMail className="contact_icon" />
                                        <a href="mailto:admin@ashwinihospitalcuttack.com" className="contact_text">admin@ashwinihospitalcuttack.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="bottom-inner">
                        <div className="copyright text-center">
                            <p>Copyright© 2024   Ashwini Hospitals   |    Privacy Policy</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;