import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/alok_kumar_mohapatra.png";
import G1 from "../../../assets/department photos/nephrology1.png";
import G2 from "../../../assets/department photos/nephrology2.png";
import G3 from "../../../assets/department photos/nephrology3.png";

function AHNephrology() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Alok kumar Mohapatra",
            qualification: "MBBS, MD(Medicine), DM(Nephrology)",
            location: "Ashwini Hospital"
        }
    ];

    const opdTimings = [
        {
            doctor: 'Dr. Alok Kumar Mohapatra',
            schedule: [
                { day: 'MON', time: '09:30 AM - 05:00 PM', centre: 'Ashwini Hospital' },
                { day: 'TUE', time: '09:30 AM - 05:00 PM', centre: 'Ashwini Hospital' },
                { day: 'WED', time: '09:30 AM - 05:00 PM', centre: 'Ashwini Hospital' },
                { day: 'THU', time: '09:30 AM - 05:00 PM', centre: 'Ashwini Hospital' },
                { day: 'FRI', time: '09:30 AM - 05:00 PM', centre: 'Ashwini Hospital' },
                { day: 'SAT', time: '09:30 AM - 05:00 PM', centre: 'Ashwini Hospital' }
            ]
        }
    ]

    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 }
    ]

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <NavbarLocation />

            {/* Nephrology Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    {opdTimings.map((opd, index) => (
                                        <div key={index}>
                                            <h5 className="opd">{opd.doctor}</h5>
                                            <table className="table table-hover table-light">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">DAYS</th>
                                                        <th scope="col">TIMINGS</th>
                                                        <th scope="col">CENTRE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {opd.schedule.map((schedule, idx) => (
                                                        <tr className="opd-table" key={idx}>
                                                            <th scope="row">{schedule.day}</th>
                                                            <td>{schedule.time}</td>
                                                            <td>{schedule.centre}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Gallery" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Popup" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The nephrology unit is managed by highly qualified and experienced senior consultants in Nephrology. The department functions as a tertiary care unit, complimented with urological services.</p>

                                        <p>The department provides specialized care for the patients with UTI, acute and chronic renal failure, hypertension, glomerulo- nephritis, check up of post kidney transplant patients and also extends support to other departments in the management of wide ranging renal problems.</p>

                                        <p>Our new renal dialysis unit is well equipped with 8 nos. of Machine including ARRT Plus to provide the renal replacement therapy for patients suffering from end stage of renal disease and acute kidney injury on a regular basis. With the support of dedicated staffs and efficient doctors, the department is committed to deliver the specialized services with care and compassion.</p>

                                        <p>In ICU cytosorb therapy is given to patients with septicaemia. Dialysis by ARRT plus machine in low blood pressure patients are also done in Ashwini Hospital. Haemodialysis Services are provided to Hepatitis B and C positive patients as well by our team of experienced consultants.</p>
                                        <p>We are well trained and equipped for the treatments of :-</p>
                                        <ul className="ul_list">
                                            <li>Outpatient clinics</li>
                                            <li>Care of inpatients with kidney disorders </li>
                                            <li>Consultation services to other departments</li>
                                            <li>Dialysis service</li>
                                            <li>Pediatric Nephrology Service</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Services available</h1>
                                    </div>

                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>USG guided kidney biopsy</li>
                                            <li>Renal Function Test (RFT)</li>
                                            <li>Urine ACR</li>
                                            <li>Serum Electrolyte & Urine Electrolyte Test</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AHNephrology;