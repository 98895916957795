import React, { useState } from "react";
import Location1 from "../../assets/Ashwini Hospital.jpg";
import Location2 from "../../assets/Ashwini Trauma Centre.jpg";
import Location3 from "../../assets/Aditya Ashwini Hospital.jpg";
// import Location4 from "../../assets/Ashwini Wellness.jpg";
// import Location5 from "../../assets/Ashwini College of Nursing.jpg";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import Popup from "./Popup";

function Location() {
    const [showPopup, setShowPopup] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [popupMessage, setPopupMessage] = useState('');

    // const handleViewMoreClick = (url, message) => {
    //     setRedirectUrl(url);
    //     setPopupMessage(message);
    //     setShowPopup(true);
    // };

    const handleClosePopup = () => {
        setShowPopup(false);
        setRedirectUrl('');
        setPopupMessage('');
    };

    const handleConfirmRedirect = () => {
        setShowPopup(false);
        window.open(redirectUrl, '_blank', 'noreferrer');
    };

    return (
        <>
            {/* Locations */}
            <div className="container mt-2 mb-5">
                <div className="sec-title mb-4">
                    <h1>Our Locations</h1>
                </div>

                <div className="row row-cols-1 row-cols-xl-3 row-cols-xl-3 row-cols-md-3 g-4 justify-content-evenly">
                    <div className="col location-block-one">
                        <div className="card h-100 inner-box">
                            <img src={Location1} className="card-img-top" alt="AshwiniHospital" />
                            <div className="card-body location">
                                <h5 className="card-title">Ashwini Hospital</h5>
                                <p className="card-text">Plot No 3, CDA, Sector-1, Cuttack-753014, Odisha</p>
                            </div>
                            <div className="card-footer show_location">
                                <a href='/AshwiniHospitalCuttack' className="small">
                                    View More Info <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col location-block-one">
                        <div className="card h-100 inner-box">
                            <img src={Location2} className="card-img-top" alt="AshwiniTraumaCentre" />
                            <div className="card-body location">
                                <h5 className="card-title">Ashwini Trauma Centre</h5>
                                <p className="card-text">CDA, Sector-1, Cuttack-753015, Odisha</p>
                            </div>
                            <div className="card-footer show_location">
                                <a href='/AshwiniTraumaCentre' className="small">
                                    View More Info <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col location-block-one">
                        <div className="card h-100 inner-box">
                            <img src={Location3} className="card-img-top" alt="AdityaAshwiniHospital" />
                            <div className="card-body location">
                                <h5 className="card-title">Aditya Ashwini Hospital</h5>
                                <p className="card-text">Plot 329/1929, Chandrasekharpur, Bhubaneswar-751017, Odisha</p>
                            </div>
                            <div className="card-footer show_location">
                                <a href='/AdityaAshwiniHospital' className="small">
                                    View More Info <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col location-block-one">
                        <div className="card h-100 inner-box">
                            <img src={Location4} className="card-img-top" alt="AshwiniWellness" />
                            <div className="card-body location">
                                <h5 className="card-title">Ashwini Wellness</h5>
                                <p className="card-text">M73, Samanta Vihar, (Near Kalinga Hospital) Nalco Square, Bhubaneswar-751016</p>
                            </div>
                            <div className="card-footer show_location">
                                <button onClick={() => handleViewMoreClick('https://ashwiniwellness.com/', 'We are redirecting to Ashwini Wellness website')} className="small">
                                    View More Info <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col location-block-one">
                        <div className="card h-100 inner-box">
                            <img src={Location5} className="card-img-top" alt="AshwiniCollegeofNursing" />
                            <div className="card-body location">
                                <h5 className="card-title">Ashwini College of Nursing</h5>
                                <p className="card-text">Ashwini College of Nursing, Sector 1, CDA, Cuttack-753014, Odisha</p>
                            </div>
                            <div className="card-footer show_location">
                                <button onClick={() => handleViewMoreClick('https://ashwinicollege.in/', 'We are redirecting to Ashwini College of Nursing website')} className="small">
                                    View More Info <MdOutlineKeyboardDoubleArrowRight className="fs-4" />
                                </button>
                            </div>
                        </div>
                    </div> */}

                    {showPopup && (
                        <Popup
                            show={showPopup}
                            message={popupMessage}
                            onClose={handleClosePopup}
                            onConfirm={handleConfirmRedirect}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default Location;