import React, { useEffect } from "react";
import "./Departments.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import ServicesBanner from "../../assets/about_banner.jpg";
import Neurology from "../../assets/service bg images/neurology.png";
import Neurosurgery from "../../assets/service bg images/neurosurgery.png";
import Orthopedics from "../../assets/service bg images/orthopedics.png";
import PlasticSurgery from "../../assets/service bg images/plastic_surgery.png";
import Pulmonary from "../../assets/service bg images/pulmonary.png";
import CriticalCare from "../../assets/service bg images/critical_care.png";
import EmergencyMedicine from "../../assets/service bg images/emergency_medicine.png";
import Cardiology from "../../assets/service bg images/cardiology.png";
import Cardiothoracic from "../../assets/service bg images/cardiothoracic.png";
import Dermatology from "../../assets/service bg images/dermatology.png";
import HandMicroVascular from "../../assets/service bg images/micro_vascular.png";
import Gynecology from "../../assets/service bg images/gynecology.png";
import Nephrology from "../../assets/service bg images/nephrology.png";
import Urology from "../../assets/service bg images/urology.png";
import OralMaxillo from "../../assets/service bg images/maxillo_facial.png";
import GSurgery from "../../assets/service bg images/general_surgery.png";
import Anesthesiology from "../../assets/service bg images/anesthesiology.png";
import Spine from "../../assets/service bg images/spine_surgery.png";
import Dental from "../../assets/service bg images/dental.png";
import Pediatrics from "../../assets/service bg images/pediatric.png";
import Physiotherapy from "../../assets/service bg images/physiotherapy.png";
import Psychology from "../../assets/service bg images/psychology.png";
import Dietetics from "../../assets/service bg images/dietetics.png";
import Radiology from "../../assets/service bg images/radiology.png";
import LaboratoryServices from "../../assets/service bg images/pathology.png";
import AlliedServices from "../../assets/service bg images/allied_services.png";

import NeuroIcon from "../../assets/services icons/neurology.png";
import NeurosurgeryIcon from "../../assets/services icons/neurosurgery.png";
import OrthoIcon from "../../assets/services icons/orthopedics.png";
import PSIcon from "../../assets/services icons/burn and plastic.png";
import PulmoIcon from "../../assets/services icons/pulmonology.png";
import CCIcon from "../../assets/services icons/critical care.png";
import EMIcon from "../../assets/services icons/emergency medicine.png";
import CardioIcon from "../../assets/services icons/cardiology.png";
import CardiothoracicIcon from "../../assets/services icons/cardiothoracic.png";
import DermaIcon from "../../assets/services icons/cosmetic and derma.png";
import HMicroIcon from "../../assets/services icons/hand vascular.png";
import GynecIcon from "../../assets/services icons/gynocology.png";
import NephrologyIcon from "../../assets/services icons/nephrology.png";
import UrologyIcon from "../../assets/services icons/nephrology.png";
import OMaxilloIcon from "../../assets/services icons/maxillo-facial.png";
import GSurgeryIcon from "../../assets/services icons/general surgery.png";
import AnesthesiologyIcon from "../../assets/services icons/anesthesiology.png";
import SpineIcon from "../../assets/services icons/spine.png";
import DentalIcon from "../../assets/services icons/dental.png";
import PediatricsIcon from "../../assets/services icons/pediatric.png";
import PhysiotherapyIcon from "../../assets/services icons/physiotherapy.png";
import PyschologyIcon from "../../assets/services icons/psychology.png";
import DieteticsIcon from "../../assets/services icons/diet.png";
import RadiologyIcon from "../../assets/services icons/rediology.png";
import LaboratoryServicesIcon from "../../assets/services icons/pathology.png";
import AlliedIcon from "../../assets/services icons/allied.png";

function Services() {
    const services = [
        {
            link: "/Neurology",
            image: Neurology,
            icon: NeuroIcon,
            title: "Neurology"
        },
        {
            link: "/Neurosurgery",
            image: Neurosurgery,
            icon: NeurosurgeryIcon,
            title: "Neurosurgery"
        },
        {
            link: "/Orthopedics",
            image: Orthopedics,
            icon: OrthoIcon,
            title: "Orthopedics Trauma & Rehabilitation"
        },
        {
            link: "/PlasticSurgery",
            image: PlasticSurgery,
            icon: PSIcon,
            title: "Burns, Plastic & Reconstructive Surgery"
        },
        {
            link: "/Pulmonary",
            image: Pulmonary,
            icon: PulmoIcon,
            title: "Pulmonary & Respiratory Medicine"
        },
        {
            link: "/CriticalCare",
            image: CriticalCare,
            icon: CCIcon,
            title: "Critical Care"
        },
        {
            link: "/EmergencyMedicine",
            image: EmergencyMedicine,
            icon: EMIcon,
            title: "Emergency Medicine"
        },
        {
            link: "/Cardiology",
            image: Cardiology,
            icon: CardioIcon,
            title: "Cardiology & Intervention"
        },
        {
            link: "/Cardiothoracic",
            image: Cardiothoracic,
            icon: CardiothoracicIcon,
            title: "Cardiothoracic Surgery"
        },
        {
            link: "/Dermatology",
            image: Dermatology,
            icon: DermaIcon,
            title: "Cosmetic Surgery, Dermatology & Lasers"
        },
        {
            link: "/HandSurgery",
            image: HandMicroVascular,
            icon: HMicroIcon,
            title: "Hand & Micro Vascular Surgery"
        },
        {
            link: "/Gynaecology",
            image: Gynecology,
            icon: GynecIcon,
            title: "Obstetrics & Gynaecology"
        },
        {
            link: "/Nephrology",
            image: Nephrology,
            icon: NephrologyIcon,
            title: "Nephrology & Dialysis"
        },
        {
            link: "/Urology",
            image: Urology,
            icon: UrologyIcon,
            title: "Urology"
        },
        {
            link: "/MaxilloFacialSurgery",
            image: OralMaxillo,
            icon: OMaxilloIcon,
            title: "Oral & Maxillo-facial Surgery"
        },
        {
            link: "/GeneralSurgery",
            image: GSurgery,
            icon: GSurgeryIcon,
            title: "General Surgery & Laparoscopic"
        },
        {
            link: "/Anesthesiology",
            image: Anesthesiology,
            icon: AnesthesiologyIcon,
            title: "Anesthesiology & Pain Management"
        },
        {
            link: "/SpineSurgery",
            image: Spine,
            icon: SpineIcon,
            title: "Spine Surgery"
        },
        {
            link: "/Dental",
            image: Dental,
            icon: DentalIcon,
            title: "Dental"
        },
        {
            link: "/Pediatric",
            image: Pediatrics,
            icon: PediatricsIcon,
            title: "Pediatrics"
        },
        {
            link: "/Physiotherapy",
            image: Physiotherapy,
            icon: PhysiotherapyIcon,
            title: "Physiotherapy & Rehabilitation"
        },
        {
            link: "/Psychology",
            image: Psychology,
            icon: PyschologyIcon,
            title: "Psychology & Psychotherapy"
        },
        {
            link: "/Dietetics",
            image: Dietetics,
            icon: DieteticsIcon,
            title: "Diet & Dietetics"
        },
        {
            link: "/Radiology",
            image: Radiology,
            icon: RadiologyIcon,
            title: "Radiology"
        },
        {
            link: "/LaboratoryServices",
            image: LaboratoryServices,
            icon: LaboratoryServicesIcon,
            title: "Laboratory Services"
        },
        {
            link: "/AlliedServices",
            image: AlliedServices,
            icon: AlliedIcon,
            title: "Allied Services"
        },
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />

            {/* Services Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={ServicesBanner} alt="ServicesBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Our Services</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Services */}
            <section className="service-section my-4">
                <div className="container">
                    <div className="sec-title mb-4">
                        <p>Ashwini group of hospitals a multi specility hospital</p>
                    </div>
                    <div className="row">
                        {services.map((service, index) => (
                            <>
                                <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                                    <div className="service-block-one fadeInUp">
                                        <div className="inner-box">
                                            <div className="image-box">
                                                <div className="image">
                                                    <a href={service.link}>
                                                        <img src={service.image} alt="DepartmentImages" />
                                                    </a>
                                                </div>
                                                <div className="icon-box">
                                                    <img src={service.icon} alt="DepartmentImages" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <span><a href={service.link}>{service.title}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </section >
        </>
    )
}

export default Services;