import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/sampat_dash.png";
import Img2 from "../../../assets/doctors/satya_mohanty.png";
import G1 from "../../../assets/department photos/Pulmonology1.png";

function AAHPulmonology() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Sampat Dash",
            qualification: "MD, DNB, IDCCM, MNAMS, EDRM",
            location: "Ashwini Hospital, Aditya Ashwini Hospital"
        },
        {
            image: Img2,
            name: "Dr. Satya Mohanty",
            qualification: "MD, IDCCM Dept. Of Pulmonary Medicine",
            location: "Ashwini Hospital, Aditya Ashwini Hospital"
        }
    ];

    const gallery = [
        { image: G1 }
    ]

    const opdTimings = [
        {
            doctor: 'Dr. Sampat Dash',
            schedule: [
                { day: 'MON', time: '03:00 PM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'FRI', time: '03:00 PM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
            ]
        },
        {
            doctor: 'Dr. Satya Mohanty',
            schedule: [
                { day: 'MON', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'TUE', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'WED', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'THU', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'FRI', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'SAT', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' }
            ]
        }
    ]

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <NavbarLocation />

            {/* Pulmonology Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    {opdTimings.map((opd, index) => (
                                        <div key={index}>
                                            <h5 className="opd">{opd.doctor}</h5>
                                            <table className="table table-hover table-light">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">DAYS</th>
                                                        <th scope="col">TIMINGS</th>
                                                        <th scope="col">CENTRE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {opd.schedule.map((schedule, idx) => (
                                                        <tr className="opd-table" key={idx}>
                                                            <th scope="row">{schedule.day}</th>
                                                            <td>{schedule.time}</td>
                                                            <td>{schedule.centre}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Gallery" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Popup" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The Pulmonary Medicine Department provides top-of-the-line services in the diagnosis and management of various Respiratory Diseases and Sleep Disorders. In-house Doctors specialized in respiratory medicine diagnose and treat conditions affecting the respiratory (breathing) system. We are well trained and equipped for the treatments of :-</p>
                                        <ul className="ul_list">
                                            <li>Asthma, COPD, Pneumonia</li>
                                            <li>Interstitial Lung Disease, Lung Cancer, Tuberculosis and Sleep Related Breathing Disorders</li>
                                            <li>The Department conducts Pulmonary Function Test for comprehensive diagnosis and management of various Respiratory Diseases</li>
                                            <li>The Department conducts Pulmonary Function Test for comprehensive lung function testing</li>
                                            <li>Bronchoscopes including endobronchial diagnostic and therapeutic procedures are also performed</li>
                                            <li>Medical thoracoscopy, including pleurodesis and biopsy of pleural lesions</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Services available */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Services available</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Complete Pulmonary Function Testing (Spirometry, Volumes, 6 min walk test)</li>
                                            <li>Diagnostic and Therapeutic Bronchoscope (adult patients)</li>
                                            <li>Noninvasive Ventilation</li>
                                            <li>FOT (Forced Oscillation Technique)</li>
                                            <li>FeNO (Fractional Exhaled Nitric Oxide)</li>
                                            <li>Cryo Lung Biopsy </li>
                                            <li>Sleep Study</li>
                                            <li>Asthma Clinic- comprehensive evaluation and management of Asthma</li>
                                            <li>COPD and smoking related diseases</li>
                                            <li>Pulmonary rehabilitation</li>
                                            <li>Tuberculosis</li>
                                            <li>Lung Cancer</li>
                                            <li>Biopsy of Pleura and Intra Thoracic Masses</li>
                                            <li>Invasive and Non-Invasive Ventilation</li>
                                            <li>Respiratory Critical Care</li>
                                            <li>The Department of Respiratory Medicine is geared to deal with the whole array of
                                                respiratory disorders like Asthma, COPD, Pneumonias, Interstitial Lung diseases,
                                                Sleep apnea & Insomnia</li>
                                            <li>Our motto is to provide personalized care to all our patients round the clock with any types of acute respiratory problems</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AAHPulmonology;