import React from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import MaxilloFacialSurgeryBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/sibani_das.jpg";

function MaxilloFacialSurgery() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Sibani Das",
            qualification: "MDS(Oral & Maxillofacial Surgery)",
            location: "Ashwini Trauma Centre"
        }
    ];

    const opdTimings = [
        {
            doctor: 'Dr. Sibani Das',
            schedule: [
                { day: 'TUE', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'THU', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'SAT', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        }
    ]

    return (
        <>
            <Navbar />

            {/* Maxillo Facial Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={MaxilloFacialSurgeryBanner} alt="MaxilloFacialSurgeryBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Oral & Maxillo-Facial Surgery</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Maxillo Facial Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    {opdTimings.map((opd, index) => (
                                        <div key={index}>
                                            <h5 className="opd">{opd.doctor}</h5>
                                            <table className="table table-hover table-light">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">DAYS</th>
                                                        <th scope="col">TIMINGS</th>
                                                        <th scope="col">CENTRE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {opd.schedule.map((schedule, idx) => (
                                                        <tr className="opd-table" key={idx}>
                                                            <th scope="row">{schedule.day}</th>
                                                            <td>{schedule.time}</td>
                                                            <td>{schedule.centre}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Oral & Maxillo-Facial Surgery is divided into paediatric and adult Maxillo-facial surgery. Paediatric cases mostly revolve around the treatment of congenital anomalies of the craniofacial skeleton and soft tissues, such as cleft lip and palate, craniosynostosis, and paediatric fractures. Adult craniofacial surgery deals mostly with fractures (of mandible, maxilla, zygoma, frontal and naso-ethmoidal area) and secondary surgeries (such as orbital reconstruction) along with orthognathic surgery. Craniofacial surgery is an important part of all plastic surgery training programs, further training and subspecialisation is obtained via a craniofacial fellowship. Craniofacial surgery is also practiced by Maxillo-Facial surgeons.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MaxilloFacialSurgery;