import React from "react";
import RedStar from "../../assets/shapes/redstar.png";
import BlueStar from "../../assets/shapes/bluestar.png";
import HexagonSymmetric from "../../assets/shapes/hexagonsymmetric.png";
import AboutusBanner from "../../assets/video-poster.jpg";

function Message() {
    return (
        <>
            {/* About Message */}
            <section className="about-section">
                <div className="pattern-layer">
                    <div className="pattern-1 rotate-me d-none d-lg-block">
                        <img src={RedStar} alt="RedStar" />
                    </div>
                    <div className="pattern-2 rotate-me d-none d-lg-block">
                        <img src={RedStar} alt="RedStar" />
                    </div>
                    <div className="pattern-3 rotate-me d-none d-lg-block">
                        <img src={BlueStar} alt="BlueStar" />
                    </div>
                    <div className="pattern-4">
                        <img src={HexagonSymmetric} alt="HexagonSymmetric" />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="image_block_one">
                                <div className="image-box">
                                    <figure className="image">
                                        <img src={AboutusBanner} alt="AboutusBanner" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div className="content_block_one">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <span className="sub-title">18 Years of Medical Excellence</span>
                                        <h1>You Can Trust Us</h1>
                                    </div>
                                    <div className="text-box">
                                        <p>Ashwini Hospital is the largest private medical centre of Cuttack and
                                            was
                                            commissioned in 2006 with an aim to provide tertiary level health care in select
                                            super-specialty branches of Medicine and Surgery. Situated at Sector 1, CDA project
                                            area, the hospital is close to the busy city centre and yet easily approachable for
                                            people through all major State roads and National Highways. Soon it carved a niche in
                                            critical care of patients with Trauma, Neuro, Cardiac, Pulmonary and other complex
                                            medical issues.</p>
                                    </div>
                                    <div className="btn-box text-center">
                                        <a href={"/About"} className="theme-btn btn-one">
                                            <span>Know More</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Message;