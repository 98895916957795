import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/Soumya_Pradhan.png";
import G1 from "../../../assets/department photos/neuro1.png";
import G2 from "../../../assets/department photos/neuro2.png";
import G3 from "../../../assets/department photos/neuro3.png";
import G4 from "../../../assets/department photos/neuro4.png";
import G5 from "../../../assets/department photos/neuro5.png";
import G6 from "../../../assets/department photos/neuro6.png";
import G7 from "../../../assets/department photos/neuro7.png";
import G8 from "../../../assets/department photos/neuro8.png";
import G9 from "../../../assets/department photos/neuro9.png";

function AAHNeurology() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Soumya Ranjan Pradhan",
            qualification: "MBBS, MD(Medicine), DM(Neurology), DrNB Junior Faculty",
            location: "Ashwini Hospital, Aditya Ashwini Hospital"
        }
    ];

    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 },
        { image: G4 },
        { image: G5 },
        { image: G6 },
        { image: G7 },
        { image: G8 },
        { image: G9 }
    ];

    const opdTimings = [
        {
            doctor: 'Dr. Soumya Ranjan Pradhan',
            schedule: [
                { day: 'MON', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'WED', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'FRI', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'SAT', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' }
            ]
        }
    ]

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <NavbarLocation />

            {/* Neurology Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span>{slide.location}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    {opdTimings.map((opd, index) => (
                                        <div key={index}>
                                            <h5 className="opd">{opd.doctor}</h5>
                                            <table className="table table-hover table-light">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">DAYS</th>
                                                        <th scope="col">TIMINGS</th>
                                                        <th scope="col">CENTRE</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {opd.schedule.map((schedule, idx) => (
                                                        <tr className="opd-table" key={idx}>
                                                            <th scope="row">{schedule.day}</th>
                                                            <td>{schedule.time}</td>
                                                            <td>{schedule.centre}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Gallery" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Popup" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Ashwini hospital is regarded as one of the leading hospital for neurology and neurosurgery in Eastern
                                            part of India. Our Department Of Neurosciences is committed to provide high quality, secondary and
                                            tertiary neuro services to patients in the region, as well as to all those who have recourse to our
                                            facility. Well accompanied by Dept of Neuroradiology, Neurointervention, Neuro anesthesia and Critical
                                            care we are able to provide 24X7 services with treatment outcomes are comparable to global statistics.
                                            We are well trained and equipped for the treatments of :-</p>
                                        <ul className="ul_list">
                                            <li>Headache and migraine</li>
                                            <li>Cerebrovascular and movement disorders</li>
                                            <li>Multiple sclerosis</li>
                                            <li>Neuropathy</li>
                                            <li>Management of neurobehavioral and memory disorders</li>
                                            <li>Alzheimer's and Parkinson's disease</li>
                                            <li>Neuromuscular diseases</li>
                                            <li>Seizure disorders</li>
                                            <li>Bell's palsy</li>
                                            <li>Cerebral palsy and related neurological disorders</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Facilities Available */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Facilities Available</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>EEG (Electro Encephalogram)</li>
                                            <li>NCV (Neuro Conduction Velocity)</li>
                                            <li>Video EEG</li>
                                            <li>EMG/EP</li>
                                            <li>Operating Microscope of Carl Zeiss</li>
                                            <li>Pneumatic & Electrical Drill Systems</li>
                                            <li>Endospine system</li>
                                            <li>Neuronavigation system(1st time in Odisha)</li>
                                            <li>Neuro endoscope system</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AAHNeurology;