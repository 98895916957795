import React from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import SpineSurgeryBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/feroze_ali.jpg";

function SpineSurgery() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Feroze Ali",
            qualification: "MBBS, MD(Ortho), Spine Fellowship"
        }
    ];

    return (
        <>
            <Navbar />

            {/* Spine Surgery Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={SpineSurgeryBanner} alt="SpineSurgeryBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Spine Surgery</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Spine Surgery Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt="Doctors Photos" />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Gallery</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The department of Spine Surgery provides the most modern surgical procedures which is at par with the best hospitals all over the country.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Types of Spinal problems managed */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Types of Spinal problems managed</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Trauma</li>
                                            <li>Infections</li>
                                            <li>Deformaties</li>
                                            <li>Degenerative conditions</li>
                                            <li>Tumors</li>
                                            <li>Spondylolisthesi</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Common Surgeries performed */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Common Surgeries performed</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Micro Discectomy-patient can walk on the same day of the surgery</li>
                                            <li>Surgeries for Spinal Trauma (Cervical,Thoracic& Lumbar)</li>
                                            <li>Surgeries for Degenerative,Spondylolisthesis,Infections & Tumors</li>
                                            <li>Kyphoplasty/Vertebroplasty-for osteoporotic fractures</li>
                                            <li>Percutaneus screw fixations</li>
                                            <li>Corrective surgeries for Adolescent and Adult deformities.(Scoliosis,Kyphosis,Kyphoscoliosis)</li>
                                        </ul>
                                        <p>We thrive to provide the best possible care to our patients at an affordable cost. It is a myth that Spine surgery is risky and leads to paralysis.With modern technology and instruments Spine Surgery is as safe as any other surgery with very good results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpineSurgery;